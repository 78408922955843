import React from 'react'

// Third Party
import styled, { css } from 'styled-components'

interface EmptySpaceProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_EmptySpace
}

const StyledEmptySpace = styled.section<{
  background: string
  desktopheight: number
  laptopheight: number
  tabletheight: number
  mobileheight: number
}>`
  ${({ background, theme }) =>
    background === 'light' &&
    css`
      background-color: ${theme.color.light};
    `}
  ${({ background, theme }) =>
    background === 'dark' &&
    css`
      background-color: ${theme.color.dark};
    `}
  ${({ background, theme }) =>
    background === 'grey' &&
    css`
      background-color: ${theme.color.grey};
    `}
  ${({ background, theme }) =>
    background === 'primary' &&
    css`
      background-color: ${theme.color.primary};
    `}

  height: ${({ desktopheight }) => `${desktopheight}px`};

  @media screen and (max-width: 1199px) {
    height: ${({ laptopheight }) => `${laptopheight}px`};
  }

  @media screen and (max-width: 991px) {
    height: ${({ tabletheight }) => `${tabletheight}px`};
  }

  @media screen and (max-width: 575px) {
    height: ${({ mobileheight }) => `${mobileheight}px`};
  }
`

const EmptySpace: React.FC<EmptySpaceProps> = ({ fields }) => (
  <StyledEmptySpace
    mobileheight={fields.mobileheight || 0}
    laptopheight={fields.laptopheight || 0}
    tabletheight={fields.tabletheight || 0}
    desktopheight={fields.desktopheight || 0}
    background={fields.background || 'light'}
  />
)

export default EmptySpace
